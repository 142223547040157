const namespaced = true

import api     from '../../api/weighing_machines.js';
import _ from 'lodash';

const state = {
     weighing_machines: [],
}
const mutations = {
     MUTATION_SET_WEIGHING_MACHINE(state, value) {

          let temporal = [];
  
          _.forEach(value, function (weighing_machine) {
               
               // weighing_machine.status_color = (weighing_machine.db_database.slice(-2) === "ff")?"error":"success";
               weighing_machine.status_color = "success";
               temporal.push(weighing_machine)
          })

          state.weighing_machines = temporal;
     },
     MUTATION_ADD_WEIGHING_MACHINE(state, value){
          state.weighing_machines.push(value)
     },
     MUTATION_UPDATE_WEIGHING_MACHINE(state, value){
          let weighing_machines = state.weighing_machines;
          state.weighing_machines = [];
          _.forEach(weighing_machines, weighingMachines => {
              if(weighingMachines.id != value.id){
                  state.weighing_machines.push(weighingMachines);
              }else{
                  state.weighing_machines.push(value);
              }
          });
     },
     MUTATION_DELETE_WEIGHING_MACHINE(state, value){
          let weighing_machines = state.weighing_machines;
          state.weighing_machines = [];
          _.forEach(weighing_machines, weighingMachines => {
               if(weighingMachines.id != value){
                    state.weighing_machines.push(weighingMachines);
               }
          });
     },
}
const actions = {
      // Name Property
     index(context, all = false) {
          return new Promise((resolve) => { 
               api.index(all).then(function (res) {
                    context.commit('MUTATION_SET_WEIGHING_MACHINE', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_WEIGHING_MACHINE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_WEIGHING_MACHINE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_WEIGHING_MACHINE', id);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
