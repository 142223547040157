const namespaced = true

import api     from '../../../api/Connection/companies.js';

const state = {
    companies : [], // almacena todos los almacenes
    warehousesfromusers: [], // almacena todos los almacenes de todos los usuarios
}
const mutations = {
     MUTATION_SET_COMPANIES(state, value) {
          state.companies = value;
     }

}
const actions = {
     // recupera unicamente todos los almacenes
     getCompanies({commit},id) {
          commit('tickets/MUTATION_SET_OVERLAY', false, { root: true });
          return new Promise((resolve) => { 
               api.getCompanies(id).then(function (res) {
                    commit('MUTATION_SET_COMPANIES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    commit('tickets/MUTATION_SET_OVERLAY', false, { root: true });
                    console.log(error)
               })
          })
     },
     cleanCompanies(context, value = []) {
          context.commit('MUTATION_SET_COMPANIES', value);
     }
     
}

const getters = {    
 
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
