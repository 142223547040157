const namespaced = true

import api from '../../../api/Sap/items.js';

const state = {
    items: [],
    itemstemp: [],
    bannerItems: false,
}
const mutations = {
    MUTATION_SET_ITEMS(state, data) {
        state.items = data;
    },
    MUTATION_SET_ITEMSTEMP(state, data) {
        state.itemstemp = data;
    },
    MUTATION_SET_BANNER(state, value) {
        state.bannerItems = value;
    }
}
const actions = {
    // Name Property
    getItems(context, { dbname }) {
        context.commit("MUTATION_SET_BANNER", true)
        return new Promise((resolve) => {
            api.getItems(dbname).then(function (res) {
                context.commit("MUTATION_SET_BANNER", false)
                context.commit('MUTATION_SET_ITEMSTEMP', res.data);
                context.commit('MUTATION_SET_ITEMS', res.data);
                resolve(res.data);
            }).catch(function (error) {
                context.commit("MUTATION_SET_BANNER", false)
                console.log(error)
            })
        })
    },
    cleanItems(context) {
        context.commit('MUTATION_SET_ITEMS', []);
    }
}

const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}
