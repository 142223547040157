const namespaced = true

import api from '../../../api/Connection/tickets.js';
import { downloadFile } from "../../../src/helpers/util.js";
import _ from 'lodash';

const state = {
    tickets: [],
    ticketstemp: [],
    articlesfromtickets: [],
    businesspartnersfromtickets: [],
    ticketsbymove: [],
    move: "",
    filters: {
        article: [],
        partnertbusiness: [],
        warehouse1: [],
        warehouse2: []
    },
    banner: false,
    overlay: false

}
const mutations = {
    MUTATION_SET_TICKETS(state, value) {
        var temporal = [];
        var { root, res } = value

        _.forEach(res, function (ticket) {
            var socio = ticket.socio_negocio
            const objetoEncontrado = _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }) ? _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }) : "";

            ticket.socio_negocio_data = objetoEncontrado
            ticket.socio_negocio_name = _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }) ? _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }).cardName : "";
            ticket.articulo_data = (_.find(root.items.items, { itemCode: ticket.articulo })) ? _.find(root.items.items, { itemCode: ticket.articulo }) : "";
            ticket.articulo_name = (_.find(root.items.items, { itemCode: ticket.articulo }))? _.find(root.items.items, { itemCode: ticket.articulo }).itemName:"";
            ticket.almancen_1_data = _.find(root.warehouses.warehouses, { codigo: ticket.almacen_1 });
            ticket.almancen_2_data = _.find(root.warehouses.warehouses, { codigo: ticket.almacen_2 });
            ticket.base_datos_data = _.find(root.companies.companies, { codigo: ticket.base_datos });
            temporal.push(ticket)
        })

        state.tickets = temporal;
    },
    MUTATION_SET_TICKETSTEMP(state, value) {
        let temporal = [];
        var { root, res } = value

        _.forEach(res, function (ticket) {
            var socio = ticket.socio_negocio
            const objetoEncontrado = _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }) ? _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }) : "";

            ticket.socio_negocio_data = objetoEncontrado
            ticket.socio_negocio_name = _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }) ? _.find(root.partnerbusiness.partnersbusiness, { "cardCode": socio }).cardName : "";
            ticket.articulo_data = (_.find(root.items.items, { itemCode: ticket.articulo })) ? _.find(root.items.items, { itemCode: ticket.articulo }) : "";
            ticket.articulo_name = (_.find(root.items.items, { itemCode: ticket.articulo }))? _.find(root.items.items, { itemCode: ticket.articulo }).itemName:"";
            ticket.almancen_1_data = _.find(root.warehouses.warehouses, { codigo: ticket.almacen_1 });
            ticket.almancen_2_data = _.find(root.warehouses.warehouses, { codigo: ticket.almacen_2 });
            ticket.base_datos_data = _.find(root.companies.companies, { codigo: ticket.base_datos });
            temporal.push(ticket)
        })
        state.ticketstemp = temporal;
    },
    MUTATION_SET_ARTICLESFROMTICKETS(state) {
        const articulos = _.uniq(_.map(state.tickets, 'articulo_data'));
        const listaArticulosUnicos = _.filter(
            articulos, articulo => {
                return articulo != ""
            })

        state.articlesfromtickets = listaArticulosUnicos;
    },
    MUTATION_SET_BUSINESSPARTNERFROMTICKETS(state) {
        const socios = _.uniq(_.map(state.tickets, 'socio_negocio_data'));
        const listaSociosUnicos = _.filter(
            socios, socio => {
                return socio != ""
            })

        state.businesspartnersfromtickets = listaSociosUnicos;
    },
    MUTATION_SET_TICKETSBYMOVE(state, value) {
        state.ticketsbymove = value;
    },
    MUTATION_SET_MOVE(state, value) {
        state.move = value
    },
    MUTATION_SET_FILTERTICKETS(state, data) {
        state.filters = data
    },
    MUTATION_SET_BANNER(state, data) {
        state.banner = data
    },
    MUTATION_SET_OVERLAY(state, value){
        state.overlay = value
    }

}
const actions = {
    getTickets({ commit, rootState, state, dispatch }, { date1, date2, company, plant }, move = state.move) {
        commit('MUTATION_SET_BANNER', true);
        
        let weighing_machine_id = rootState.configuration.weighing_machine.id;

        return new Promise((resolve) => {
            
            api.getTickets(date1, date2, company, plant, weighing_machine_id).then(function (res) {
                
                if (move != "") {
                    let ticketstipo3 = _.filter(res.data, ticket => ticket.tipo <= 3)
                    commit('MUTATION_SET_TICKETSTEMP', { res: ticketstipo3, root: rootState });
                    let ticketsmove = _.filter(state.ticketstemp, { 'movimiento': move });
                    commit('MUTATION_SET_TICKETSBYMOVE', ticketsmove);
                    commit('MUTATION_SET_TICKETS', { res: ticketsmove, root: rootState });
                    commit('MUTATION_SET_ARTICLESFROMTICKETS');
                    commit('MUTATION_SET_BUSINESSPARTNERFROMTICKETS');
                    commit('MUTATION_SET_BANNER', false);
                    commit('MUTATION_SET_OVERLAY', false);
                } else {
                    commit('MUTATION_SET_TICKETS', { res: res.data, root: rootState });
                    commit('MUTATION_SET_TICKETSTEMP', { res: res.data, root: rootState });
                    commit('MUTATION_SET_ARTICLESFROMTICKETS');
                    commit('MUTATION_SET_BUSINESSPARTNERFROMTICKETS');
                    resolve(res.data);
                    dispatch('filterstickets', state.filters)
                    commit('MUTATION_SET_BANNER', false);
                    commit('MUTATION_SET_OVERLAY', false)
                    
                }
            }).catch(function (error) {

                commit('MUTATION_SET_OVERLAY', false)
                commit('MUTATION_SET_BANNER', false);
                
                // limpieza de vista y notificacion de fallo de conexion
                if (move != ""){
                    dispatch('cleanStatestooffline',{ root: true });
                }else{
                    dispatch('cleanStatestooffline',{ root: true });
                    dispatch('cleanmove', { root: true });
                }

                // limpieza de vista
                commit('configuration/SET_WEIGHING_MACHINE',{},{root: true });

                console.log(error)
            })
        })
    },
    filterstickets({ commit, rootState, state }, value) {
        commit('MUTATION_SET_FILTERTICKETS', value);

        if (state.move != "") {
            commit('MUTATION_SET_TICKETS', { res: state.ticketsbymove, root: rootState })

            let data = _.filter(state.tickets, ticket => _.map(value.warehouse2, 'code').includes(ticket.almacen_2) ||  _.map(value.warehouse1, 'code').includes(ticket.almacen_1) || value.article.includes(ticket.articulo_name) || value.partnertbusiness.includes(ticket.socio_negocio_name))

            if (data.length > 0) {
                commit('MUTATION_SET_TICKETS', { res: data, root: rootState })
            } else {
                if ((_.isEmpty(value.article) && _.isEmpty(value.partnertbusiness) && _.isEmpty(value.warehouse1) && _.isEmpty(value.warehouse2)))
                    commit('MUTATION_SET_TICKETS', { res: state.ticketsbymove, root: rootState })
                else
                    commit('MUTATION_SET_TICKETS', [])
            }

        } else {
            commit('MUTATION_SET_TICKETS', { res: state.ticketstemp, root: rootState })
            let data = _.filter(state.tickets, ticket => _.map(value.warehouse2, 'code').includes(ticket.almacen_2) ||  _.map(value.warehouse1, 'code').includes(ticket.almacen_1) || value.article.includes(ticket.articulo_name) || value.partnertbusiness.includes(ticket.socio_negocio_name))
            if (data.length > 0) {
                commit('MUTATION_SET_TICKETS', { res: data, root: rootState })
            } else {
                if ((_.isEmpty(value.article) && _.isEmpty(value.partnertbusiness) && _.isEmpty(value.warehouse1) && _.isEmpty(value.warehouse2)))
                    commit('MUTATION_SET_TICKETS', { res: state.ticketstemp, root: rootState })
                else
                    commit('MUTATION_SET_TICKETS', [])
            }
        }
    },
    filtermovimiento({ commit, state, dispatch }, value) {
        state.move = value;
        let ticketsmove = _.filter(state.ticketstemp, { 'movimiento': value });
        commit('MUTATION_SET_TICKETSBYMOVE', ticketsmove);
        commit('MUTATION_SET_ARTICLESFROMTICKETS');
        commit('MUTATION_SET_BUSINESSPARTNERFROMTICKETS');
        commit('MUTATION_SET_TICKETS', ticketsmove);
        dispatch('filterstickets', state.filters)
    },
    cleanTickets(context, value = []) {
        context.commit("MUTATION_SET_TICKETS", value);
    },
    cleanTicketsTemp(context, value = []) {
        context.commit("MUTATION_SET_TICKETSTEMP", value);
    },
    cleanTicketsbymove(context, value = []){
        context.commit("MUTATION_SET_TICKETSBYMOVE", value);
    },
    cleanmove(context, value = "") {
        context.commit("MUTATION_SET_MOVE", value)
    },
    cleanArticlesfromtickets(context, value = []) {
        context.state.articlesfromtickets = value;
    },
    cleanFilters(context, value = {}) {
        context.state.filters = value;
    },
    cleanBusinesspartnersfromtickets(context, value = []){
        context.state.businesspartnersfromtickets = value;
    },
    cleanStatestooffline(context) {
        context.dispatch('tickets/cleanArticlesfromtickets', [], { root: true });
        context.dispatch('tickets/cleanTicketsTemp', [], { root: true });
        context.dispatch('tickets/cleanTickets', [], { root: true });
        context.dispatch('items/cleanItems', [], { root: true });
        context.dispatch('partnerbusiness/cleanPartnersbusiness', [], { root: true });
        context.dispatch('tickets/cleanBusinesspartnersfromtickets', [], { root: true });
        context.dispatch('tickets/cleanTicketsbymove', [], { root: true })
   },

   exportExcelTickets(context, data) {
    return new Promise((resolve) => {
        // context.commit("MUTATION_SET_LOADING_DOWNLOAD", true);
        api.exportExcel(data.data)
            .then(function (res) {
                downloadFile(res.data, data.name);
                // context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                resolve(res.data);
            })
            .catch((e) => {
                // context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                context.dispatch(
                    "notifications/add",
                    {
                        color: "error",
                        visibility: true,
                        timeout: 5000,
                        text: e.messages,
                    },
                    { root: true }
                );
            });
    });
},


}

const getters = {
    getTicketsExcel(state) {
        return  _.map(state.tickets, (item) => [
            item.doc_num,
            item.folio,
            item.catEstatus,
            item.typeDocument,
            item.articulo,
            item.articulo_data.itemName,
            item.cantidad,
            item.unidad_medida,
            item.socio_negocio,
            item.base_datos_data.nombre,
            item.fecha_tara,
            item.hora_tara,
            item.tara,
            item.fecha_bruto,
            item.hora_bruto,
            item.bruto,
            item.neto,
            item.planta,
            item.almancen_1_data.nombre,
            item.almancen_2_data ? item.almancen_2_data.nombre : 'No cuenta con el almacen destino',
            item.nombre_operador,
            item.matricula,
            item.transportista,
            item.densidad
        ]);
    },
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}
