let prefix = '/api/user-warehouses';

// traer usuarios con sus almacenes
function getWarehousesfromUsers(){
    return window.axios.get(prefix);
}
// trae todos los almacenes
function getWarehouses() {
    return window.axios.get('api'+'/warehouses');
}
//Asisgnar/Quitar almacenes a usuarios
function assignWarehouseToUser(data){
    return window.axios.post(prefix,data);
}
function retractWarehouseFromUser(user_id, warehouse_id){
    return window.axios.delete(prefix+'/delete/'+user_id+'/'+warehouse_id);
}

export default {
    getWarehouses,
    getWarehousesfromUsers,
    assignWarehouseToUser,
    retractWarehouseFromUser

}