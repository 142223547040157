import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
  },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/logout',
    name: 'logout',
    visible: false,
  },
  {
    path: '/home',
    name: 'home',
    visible: false,
    meta: {
      title: 'Inicio',
    }
  },
  {
    path: '/users',
    name: 'users',
    visible: true,
    meta: {
      title: 'Administración / Usuarios, Permisos y Roles',
      permissions: [
        'get-users', 'store-users', 'update-users', 'delete-users',
        'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
        'get-roles', 'store-roles', 'update-abilities', 'delete-abilities', 'warehouse-to-user'
      ],
    },
  },
  {
    path: '/settings/weighing-machines',
    name: 'settings/weighing-machines',
    visible: true,
    meta: {
      title: 'Configuraciones / Báscula',
      permissions: [
        'get-weighing-machines', 'store-weighing-machines', 'update-weighing-machines', 'delete-weighing-machines'
      ],
    },
  },
  {
    path: '/catalogue/departaments',
    name: 'catalogue/departaments',
    visible: true,
    meta: {
      title: 'Catálogos / Departamentos',
      permissions: [
        'nav-departament'
      ],
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    visible: true,
    meta: {
      title: 'Bascula / Tickets',
      permissions: [
        'get-tickets', 'store-tickets', 'create-request-ticket'
      ],
    },
  },
  {
    path: '/transferencias',
    name: 'transferencias',
    visible: true,
    meta: {
      title: 'Transferencias',
      permissions: [
        'get-tickets', 'store-tickets', 'create-request-ticket'
      ],
    },
  },
  {
    path: '/tickets/solicitudes-cambio',
    name: 'tickets/solicitudes-cambio',
    visible: true,
    meta: {
      title: 'Bascula / Solicitudes de Cambio',
      permissions: ['nav-change-request'],
    },
  },
  {
    path: '/catalogue/tickets-fields',
    name: 'catalogue/tickets-fields',
    visible: true,
    meta: {
      title: 'Catálogos / Campos del Ticket',
      permissions: ['nav-tickets-field'],
    },
  },
  {
    path: '/tickets/mis-soportes',
    name: 'tickets/mis-soportes',
    visible: true,
    meta: {
      title: 'Bascula / Mis Soportes',
      permissions: ['my-change-request'],
    },
  },
  {
    path: '/catalogue/societies',
    name: 'catalogue/societies',
    visible: true,
    meta: {
      title: 'Catálogos / Sociedades',
      permissions: ['nav-societies'],
    },
  },
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  // mode: 'history',
  base: __dirname,
  routes
})

export default router