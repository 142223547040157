const namespaced = true

import api from '../../api/tickets_fields.js';
import _ from 'lodash';

const state = {
    ticketsFields: [],
    ticketField: {
        id: null,
        field: '',
        alias: '',
        estatus: false
    },
    items: [
        'Activado',
        'Desactivado'
    ]
}
const mutations = {
    MUTATION_SET_TICKETS_FIELDS(state, value) {
        state.ticketsFields = value;
    },
    MUTATION_SAVE_TICKETS_FIELD(state, value) {
        state.ticketsFields.push(value);
    },
    MUTATION_UPDATE_TICKETS_FIELD(state, value) {
        let ticketsFields = state.ticketsFields;

        state.ticketsFields = [];
        _.forEach(ticketsFields, (ticketField) => {
            state.ticketsFields.push(
                ticketField.id == value.id
                    ? value
                    : ticketField
            );
        });
    },
    MUTATION_DELETE_TICKETS_FIELD(state, id) {
        let ticketsFields = state.ticketsFields;
        state.ticketsFields = [];
        _.forEach(ticketsFields, (ticketField) => {
            if (ticketField.id != id) {
                state.ticketsFields.push(ticketField);
            }
        });
    },

}
const actions = {
    getTicketsFields(context) {
        return new Promise((resolve, reject) => {
            api.get().then(res => {
                context.commit('MUTATION_SET_TICKETS_FIELDS', res.data.data);
                resolve(res.data);
            }).catch(function (error) {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            })
        })
    },
    saveTicketField(context, data) {
        return new Promise((resolve, reject) => {
            api.store(data).then(res => {
                context.commit('MUTATION_SAVE_TICKETS_FIELD', res.data.data);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            }).catch(error => {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            })
        })
    },
    updateTicketField(context, { id, data }) {
        return new Promise((resolve, reject) => {
            api.update(id, data).then(res => {
                context.commit('MUTATION_UPDATE_TICKETS_FIELD', res.data.data);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            }).catch(error => {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            });
        });
    },
    deleteTicketField(context, id) {
        return new Promise((resolve, reject) => {
            api.deleted(id).then(res => {
                context.commit('MUTATION_DELETE_TICKETS_FIELD', id);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            }).catch(error => {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            });
        });
    },
}

const getters = {

}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}
