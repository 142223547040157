const namespaced = true
import _ from 'lodash';

const state = {
    activities : [],
}
const mutations = {
     SET_ACTIVITIES(state, value) {
          let obj =_.find(state.activities,{id : value.id});
          (obj)?state.activities.splice(state.activities.indexOf(obj), 1):null          
          state.activities.push(value);
      },
}
const actions = {
     save(context , activities){
          context.commit('SET_ACTIVITIES', activities )
       }
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
