let prefix = '/api/ticketField';

function get() {
    return window.axios.get(prefix);
}
function store(data) {
    return window.axios.post(prefix, data);
}
function update(id, data) {
    return window.axios.patch(prefix+'/'+id, data);
}
function deleted(id){
    return window.axios.delete(prefix+'/'+id);
}

export default {
    get,  
    store,
    update,
    deleted  
}