let prefix = '/api/change-request';

function get() {
    return window.axios.get(prefix);
}

function getMyChangeRequest(data) {
    return window.axios.get(prefix+'/my-change-request',{params:data});
}

function accpetChangeRequest(id) {
    return window.axios.post(prefix+'/accept/'+id);
}

function declineChangeRequest(id,data) {
    return window.axios.post(prefix+'/decline/'+id,data);
}
function gethistory(data) {
    return window.axios.get(prefix+'/history',{params:data});
}

export default {
    get,   
    getMyChangeRequest,
    accpetChangeRequest,
    declineChangeRequest,
    gethistory
}