const namespaced = true

import api from '../../api/departaments.js';
import _ from 'lodash';

const state = {
    departaments: [],
    departament: {
        id: null,
        departament: ''
    }
}
const mutations = {
    MUTATION_SET_DEPARTAMENTS(state, value) {
        state.departaments = value;
    },
    MUTATION_SAVE_DEPARTAMENT(state, value) {
        state.departaments.push(value);
    },
    MUTATION_UPDATE_DEPARTAMENT(state, value) {
        let departaments = state.departaments;
        state.departaments = [];
        _.forEach(departaments, (departament) => {
            state.departaments.push(
                departaments.id == value.id
                    ? value
                    : departament
            );
        });
    },
    MUTATION_DELETE_DEPARTAMENT(state, id) {
        let departaments = state.departaments;
        state.departaments = [];
        _.forEach(departaments, (departament) => {
            if (departament.id != id) {
                state.departaments.push(departament);
            }
        });
    },

}
const actions = {
    getDepartaments(context) {
        return new Promise((resolve,reject) => {
            api.get().then(res=> {
                context.commit('MUTATION_SET_DEPARTAMENTS', res.data.data);
                resolve(res.data);
            }).catch(function (error) {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            })
        })
    },
    saveDepartamento(context, data) {
        return new Promise((resolve,reject) => {
            api.store(data).then(res => {
                context.commit('MUTATION_SAVE_DEPARTAMENT', res.data.data);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            }).catch(error => {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            })
        })
    },
    updateDepartamento(context, { id, data }) {
        return new Promise((resolve, reject) => {
            api.update(id, data).then(res => {
                context.commit('MUTATION_UPDATE_DEPARTAMENT', res.data.data);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            }).catch(error => {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            });
        });
    },
    deleteDepartament(context, id){
        return new Promise((resolve, reject) => {
             api.deleted(id).then(res => {
                  context.commit('MUTATION_DELETE_DEPARTAMENT', id);
                  resolve(res.data);
                  context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
             }).catch(error => {
                  reject(error);
                  context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
             });
        });
   },
}

const getters = {

}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}
