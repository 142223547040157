const namespaced = true

import api     from '../../api/local_warehouses.js';
import _ from 'lodash';

const state = {
    warehouses : [], // almacena todos los almacenes
    warehousesfromusers: [], // almacena todos los almacenes de todos los usuarios
}
const mutations = {
     MUTATION_SET_WAREHOUSES(state, value) {
          state.warehouses = value;
     },
     MUTATION_SET_WAREHOUSESFROMUSERS(state, value){
          state.warehousesfromusers = value;
     },
     MUTATION_ADD_WAREHOUSESFROMUSERS(state, value){ 
          state.warehousesfromusers.push(value);
     },
     MUTATION_TRY_UPDATE_WAREHOUSESFROMUSERS(state,value){
          state.warehousesfromusers = _.filter(state.warehousesfromusers, function(warehousesfromuser) { return warehousesfromuser.warehouse_id != value; });
     }

}
const actions = {
     // recupera unicamente todos los almacenes
     getWarehouses({ commit, rootState}) {

          let weighing_machine_id = rootState.configuration.weighing_machine.id;

          return new Promise((resolve) => { 
               api.getWarehouses(weighing_machine_id).then(function (res) {
                    commit('MUTATION_SET_WAREHOUSES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     // recupera todos los usuarios cada uno con sus almacenes
     getWarehousesfromUsers(context) {
          return new Promise((resolve) => { 
               api.getWarehousesfromUsers().then(function (res) {
                    context.commit('MUTATION_SET_WAREHOUSESFROMUSERS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     //Asignar/Quitar Almacen a Usuario
     assignWarehouseToUser(context,data){
          return new Promise((resolve, reject) => {
          api.assignWarehouseToUser(data).then(resp => {
               context.commit('MUTATION_ADD_WAREHOUSESFROMUSERS', resp.data);
               resolve(resp.data);
          }).catch(error => {
               reject(error);
          });
          });
     },
     retractWarehouseFromUser(context, {user_id, warehouse_id}){
          return new Promise((resolve, reject) => {
          api.retractWarehouseFromUser(user_id, warehouse_id).then(resp => {
               context.commit('MUTATION_TRY_UPDATE_WAREHOUSESFROMUSERS', warehouse_id);
               resolve(resp.data);
          }).catch(error => reject(error));
          });
     },
     cleanWarehouses(context, value = []){
          context.commit('MUTATION_SET_WAREHOUSES', value);
     }
     
}

const getters = {    
 
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
