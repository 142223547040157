import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);

import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import authentication       from  './modules/authentication.js';
import activities           from './modules/activities.js';
import abilities            from  './modules/abilities.js';
import roles                from  './modules/roles.js';
import files                from  './modules/files.js';
import maps                 from  './modules/maps.js';
import user                 from  './modules/user.js';
import weighing_machines    from  './modules/weighing_machines.js';
import local_warehouses     from  './modules/local_warehouses.js';
import departaments         from  './modules/departaments.js';
import ticketsFields        from  './modules/tickets_fields.js';
import ticketsChangeRequest from  './modules/tickets_change_request.js';
import societies             from  './modules/societies.js';
// modulo de Connection
import warehouses           from  './modules/Connection/warehouses.js';
import plants               from  './modules/Connection/plants.js';
import companies            from  './modules/Connection/companies.js'
import tickets              from  './modules/Connection/tickets.js'
// module sap
import items                 from  './modules/Sap/items.js'
import partnerbusiness      from  './modules/Sap/partnerbusiness.js'

const dataState = createPersistedState({
  paths: ['configuration','activities'],
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    activities,
    abilities,
    roles,
    files,
    maps,
    user,
    weighing_machines,
    warehouses,
    plants,
    tickets,
    companies,
    items,
    partnerbusiness,
    local_warehouses,
    departaments,
    ticketsFields,
    ticketsChangeRequest,
    societies
  },
  plugins: [dataState]
})