let prefix = '/api/weighing-machines';

function index() {
    return window.axios.get(prefix);
}
function store(data) {
    return window.axios.post(prefix, data);
}
function update(id, data) {
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.post(prefix+'/'+id+'/delete');
}
export default {
    index,
    store,
    update,
    deleted,
}