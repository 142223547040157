const namespaced = true

import api     from '../../../api/Connection/warehouses.js';

const state = {
    warehouses : [], // almacena todos los almacenes
    warehousesfromusers: [], // almacena todos los almacenes de todos los usuarios
}
const mutations = {
     MUTATION_SET_WAREHOUSES(state, value) {
          state.warehouses = value;
     }

}
const actions = {
     // recupera unicamente todos los almacenes
     getWarehouses({ commit, rootState, dispatch }) {
          commit('tickets/MUTATION_SET_OVERLAY', true, { root: true });

          let weighing_machine_id = rootState.configuration.weighing_machine.id;

          return new Promise((resolve, reject) => { 
               api.getWarehouses(weighing_machine_id).then(function (res) {
                    commit('MUTATION_SET_WAREHOUSES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    commit('tickets/MUTATION_SET_OVERLAY', false, { root: true });

                    let movefromtickets = rootState.configuration.move;

                    // limpieza de states
                    if (movefromtickets != "") {
                         dispatch('partnerbusiness/cleanStatestooffline',"", { root: true });
                    } else {
                         dispatch('partnerbusiness/cleanStatestooffline',"", { root: true });
                         dispatch('tickets/cleanmove', { root: true });
                    }

                    // limpieza de vista
                    commit('configuration/SET_WEIGHING_MACHINE', {}, { root: true });

                    console.log(error)
                    reject(error)
               })
          })
     },
     cleanWarehouses(context, value = []){
          context.commit('MUTATION_SET_WAREHOUSES', value);
     }
     
}

const getters = {    
 
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
